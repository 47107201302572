<template>
  <div class="start-window">
    <div class="b21">{{ $t("AUTH_COMMON_SIGNIN_TO") }}</div>
    <form>
      <label for="t1" class="text">
        <span>{{ $t("AUTH_COMMON_EMAIL") }}</span>
        <input
          type="text"
          class="inp-st"
          id="t1"
          placeholder
          v-model="email"
          @change="emailChange"
          @input="emailChange"
          tabindex="1"
        />
        <p class="login-alerts" v-if="emailMessage">{{ emailMessage }}</p>
      </label>

      <label for="t2" class="password">
        <span>{{ $t("AUTH_COMMON_PASSWORD") }}</span>
        <a
          href="/"
          @click.prevent.stop="$router.push('forgot')"
          class="forgot-password"
          >{{ $t("AUTH_COMMON_FORGOT_YOUR_PASS") }}</a
        >
        <input
          type="password"
          class="inp-st"
          id="t2"
          placeholder
          v-model="password"
          @change="passwordChange"
          @input="passwordChange"
          tabindex="2"
        />
        <p class="login-alerts" v-if="passwordMessage">
          {{ passwordMessage }}
        </p>
        <input
          type="checkbox"
          name="checkbox"
          id="y2"
          class="inp-chk"
          v-model="checked"
          tabindex="3"
        />
        <label for="y2" class="inp-chk">{{ $t("AUTH_SIGNIN_STAY_IN") }}</label>
      </label>
      <button class="btn-st-bg disabled" @click.prevent.stop="login">
        {{ $t("AUTH_COMMON_ENTER") }}
      </button>
    </form>
    <div class="devider"><span>{{ $t("AUTH_OR") }}</span></div>
    <button class="custom-google-btn" @click="handleGoogleLogin">
      <img src="@/assets/images/goog.svg"/>
      {{ $t("AUTH_COMMON_SIGNIN_WITH_GOOGLE") }}
    </button>
  </div>
</template>

<script>
import { AUTH_LOGIN, AUTH_GOOGLE } from "@/store/actions/auth";
import { mapGetters } from "vuex";
import pageUtils from "../../models/page-utils";

export default {
  name: "Login",
  computed: {
    ...mapGetters(["authStatus"]),
  },
  data() {
    return {
      email: "",
      password: "",
      checked: true,
      emailMessage: "",
      passwordMessage: "",
      errorCode: "",
      isRequesting: false,
    };
  },
  computed: {
    locale() {
      return this.$locale.AUTH;
    },
    isValidPassword() {
      return pageUtils.isValidPassword(this.password);
    },
    isValidEmail() {
      return pageUtils.isValidEmail(this.email);
    },
  },
  methods: {
    emailChange(event) {
      this.emailMessage = "";
      this.passwordMessage = "";
      const email = this.email;
      if (email.length == 0) {
        this.emailMessage = "";
      } else {
        const result = this.isValidEmail;
        if (!result) {
          this.emailMessage = $t('AUTH_COMMON_VALID_EMAIL');
        } else {
          this.emailMessage = "";
        }
      }
    },
    passwordChange(event) {
      this.emailMessage = "";
      this.passwordMessage = "";

      const password = this.password;
      if (password.length == 0) {
        this.passwordMessage = "";
        return false;
      } else {
        const result = this.isValidPassword;

        if (!result) {
          this.passwordMessage = $t('AUTH_COMMON_VALID_PASSWORD');
          return true;
        } else {
          this.passwordMessage = "";
          return false;
        }
      }
    },
    async login() {
      const password = this.isValidPassword;
      const email = this.isValidEmail;

      if (password && email && !this.isRequesting) {
        this.isRequesting = true;
        const login = {
          password: this.password,
          email: this.email,
          checked: this.checked,
        };
        try {
          await this.$store.dispatch(AUTH_LOGIN, login);
          this.$router.push({ path: "/main" });
        } catch (err) {
          this.passwordMessage = this.locale.ERROR[err.code]; //*Archi
        }
        this.isRequesting = false;
      }
    },
    async handleCredentialResponse(response) {
        console.log(response.credential)
        if (response.credential && !this.isRequesting) {
          this.isRequesting = true;
          try {
            await this.$store.dispatch(AUTH_GOOGLE, { credential: response.credential });
            this.$router.push({ path: "/main" });
          } catch (err) {
            this.passwordMessage = this.locale.ERROR[err.code]; //*Archi
          }
          this.isRequesting = false;
        }
    },
    handleGoogleLogin() {
      this.googleButtonWrapper.click();
    },
    createFakeGoogleWrapper() {
      const googleLoginWrapper = document.createElement("div");
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");
      document.body.appendChild(googleLoginWrapper);

      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");
      return {
        click: () => {
          googleLoginWrapperButton.click();
        },
      };
    },
  },
  mounted() {
    const gClientId = "127640128797-dj0ifmjvpl05t21o2k7fijvtqs5o36cu.apps.googleusercontent.com";
    window.google.accounts.id.initialize({
      client_id: gClientId,
      ux_mode: "popup",
      callback: this.handleCredentialResponse,
    });

    this.googleButtonWrapper = this.createFakeGoogleWrapper();
  },
};
</script>

<style>

.custom-google-button {
  display: none;
}
</style>
